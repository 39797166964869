// Generated by Framer (acd6bc0)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {TbCWGL_Vp: {hover: true}};

const cycleOrder = ["TbCWGL_Vp", "QMNS4VLzW"];

const serializationHash = "framer-6U9fP"

const variantClassNames = {QMNS4VLzW: "framer-v-1gc13uv", TbCWGL_Vp: "framer-v-1sk38wc"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Closed: "TbCWGL_Vp", Open: "QMNS4VLzW"}

const getProps = ({click, height, hover, id, width, ...props}) => { return {...props, PWSlWeqS6: hover ?? props.PWSlWeqS6, qZuVjJZe_: click ?? props.qZuVjJZe_, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "TbCWGL_Vp"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;hover?: any;click?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, PWSlWeqS6, qZuVjJZe_, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "TbCWGL_Vp", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap1ylpw9r = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (qZuVjJZe_) {const res = await qZuVjJZe_(...args);
if (res === false) return false;}
})

const onMouseEnter170i0h9 = activeVariantCallback(async (...args) => {
setGestureState({isHovered: true})
if (PWSlWeqS6) {const res = await PWSlWeqS6(...args);
if (res === false) return false;}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={{webPageId: "Ncqs9Y9B6"}} {...addPropertyOverrides({QMNS4VLzW: {href: "javascript:history.back(-1)", openInNewTab: false}}, baseVariant, gestureVariant)}><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-1sk38wc", className, classNames)} framer-ih5ig9`} data-framer-name={"Closed"} data-highlight layoutDependency={layoutDependency} layoutId={"TbCWGL_Vp"} onMouseEnter={onMouseEnter170i0h9} onTap={onTap1ylpw9r} ref={ref ?? ref1} style={{backgroundColor: "var(--token-4c5b5684-a6a8-443a-9b20-337cf2c32454, rgb(203, 179, 255))", borderBottomLeftRadius: 200, borderBottomRightRadius: 200, borderTopLeftRadius: 200, borderTopRightRadius: 200, ...style}} variants={{"TbCWGL_Vp-hover": {backgroundColor: "rgb(188, 167, 235)"}, QMNS4VLzW: {backgroundColor: "var(--token-4c5b5684-a6a8-443a-9b20-337cf2c32454, rgb(4, 171, 229))"}}} {...addPropertyOverrides({"TbCWGL_Vp-hover": {"data-framer-name": undefined}, QMNS4VLzW: {"data-framer-name": "Open"}}, baseVariant, gestureVariant)}><motion.div className={"framer-1cihnry"} layoutDependency={layoutDependency} layoutId={"ScsQjyqhw"} style={{backgroundColor: "var(--token-ea674574-6087-4500-a1de-7220a96fee8e, rgb(255, 255, 255))", borderBottomLeftRadius: 120.5, borderBottomRightRadius: 120.5, borderTopLeftRadius: 120.5, borderTopRightRadius: 120.5, rotate: 0}} variants={{QMNS4VLzW: {rotate: -45}}}/><motion.div className={"framer-1fvuwum"} layoutDependency={layoutDependency} layoutId={"Tvq7_ZfIJ"} style={{backgroundColor: "var(--token-ea674574-6087-4500-a1de-7220a96fee8e, rgb(255, 255, 255))", borderBottomLeftRadius: 187.5, borderBottomRightRadius: 187.5, borderTopLeftRadius: 187.5, borderTopRightRadius: 187.5, rotate: 0}} variants={{QMNS4VLzW: {rotate: 45}}}/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-6U9fP.framer-ih5ig9, .framer-6U9fP .framer-ih5ig9 { display: block; }", ".framer-6U9fP.framer-1sk38wc { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 4px; height: 40px; justify-content: center; overflow: hidden; padding: 0px; position: relative; text-decoration: none; width: 40px; will-change: var(--framer-will-change-override, transform); }", ".framer-6U9fP .framer-1cihnry { flex: none; height: 3px; left: calc(50.00000000000002% - 24px / 2); overflow: visible; position: absolute; top: 14px; width: 24px; z-index: 1; }", ".framer-6U9fP .framer-1fvuwum { bottom: 15px; flex: none; height: 3px; left: calc(50.00000000000002% - 24px / 2); overflow: visible; position: absolute; width: 24px; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-6U9fP.framer-1sk38wc { gap: 0px; } .framer-6U9fP.framer-1sk38wc > * { margin: 0px; margin-bottom: calc(4px / 2); margin-top: calc(4px / 2); } .framer-6U9fP.framer-1sk38wc > :first-child { margin-top: 0px; } .framer-6U9fP.framer-1sk38wc > :last-child { margin-bottom: 0px; } }", ".framer-6U9fP.framer-v-1gc13uv .framer-1cihnry { order: 0; top: calc(50.00000000000002% - 3px / 2); }", ".framer-6U9fP.framer-v-1gc13uv .framer-1fvuwum { bottom: unset; order: 1; top: calc(50.00000000000002% - 3px / 2); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 40
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"QMNS4VLzW":{"layout":["fixed","fixed"]},"hG0HLHIkA":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"PWSlWeqS6":"hover","qZuVjJZe_":"click"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerKy2sp5Q8i: React.ComponentType<Props> = withCSS(Component, css, "framer-6U9fP") as typeof Component;
export default FramerKy2sp5Q8i;

FramerKy2sp5Q8i.displayName = "Trigger";

FramerKy2sp5Q8i.defaultProps = {height: 40, width: 40};

addPropertyControls(FramerKy2sp5Q8i, {variant: {options: ["TbCWGL_Vp", "QMNS4VLzW"], optionTitles: ["Closed", "Open"], title: "Variant", type: ControlType.Enum}, PWSlWeqS6: {title: "Hover", type: ControlType.EventHandler}, qZuVjJZe_: {title: "Click", type: ControlType.EventHandler}} as any)

addFonts(FramerKy2sp5Q8i, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})